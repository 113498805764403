import {createAction, handleActions} from 'redux-actions'
import App from '../models/App'
import * as moment from 'moment'
import * as momentLocalizer from 'react-widgets-moment'
import * as numberLocalizer from 'react-widgets-simple-number'
import AuthenticatedUser from './AuthenticatedUser'

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = new App()

// ------------------------------------
// Constants
// ------------------------------------
export const INITIALIZE_START = 'App.INITIALIZE_START'
export const INITIALIZE_COMPLETE = 'App.INITIALIZE_COMPLETE'
export const INITIALIZE_FAILED = 'App.INITIALIZE_FAILED'
export const RESET = 'App.RESET'

// ------------------------------------
// Actions
// ------------------------------------
export const startInitialize = createAction(INITIALIZE_START, (payload) => payload)
export const completeInitialize = createAction(INITIALIZE_COMPLETE, (payload) => payload)
export const failedInitialize = createAction(INITIALIZE_FAILED, (payload) => payload)
export const resetAction = createAction(RESET)
const UNAUTHORIZED = 401

export const initialize = (history) => {

  return (dispatch) => {

    dispatch(startInitialize())

    momentLocalizer(moment)
    numberLocalizer()

    return dispatch(AuthenticatedUser.authenticate(history))
      .then(() => dispatch(completeInitialize()))
      .catch((error) => dispatch(failedInitialize(error)))
  }
}

export const actions = {
  initialize
}

export function handleGenericError(error) {

  return dispatch => {

    if (error.code === UNAUTHORIZED) {

      dispatch(AuthenticatedUser.resetAuthenticatedUser())
      dispatch(resetAction())
    }

    throw error
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const startOfInitialize = (state) => {

  return state
    .set('initializing', true)
    .set('initialized', false)
    .set('error', null)
}

const endOfInitialize = (state, error?) => {

  return state
    .set('initializing', false)
    .set('initialized', !error)
    .set('error', error)
}

const ACTION_HANDLERS = {
  [INITIALIZE_START]: (state) => startOfInitialize(state),
  [INITIALIZE_COMPLETE]: (state) => endOfInitialize(state),
  [INITIALIZE_FAILED]: (state, {payload}) => endOfInitialize(state, payload),
  [RESET]: () => endOfInitialize(initialState)
}

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState)
