import PresentEventPhotosView from '../components/PresentEventPhotosView'
import {withNamespaces} from 'react-i18next'
import EventModule from '../../../modules/Event'
import EventPhotosModule from '../../../modules/EventPhotos'
import PhotoMetadataModule from '../../../modules/PhotoMetadataMap'
import {connect} from 'react-redux'
import {PaginationContext} from '../../../models/Pagination'

const mapActionCreators = {
  updateCriteria: EventPhotosModule.updateCriteria,
  getPhotos: EventPhotosModule.getModels,
  getPhotoMetadata: PhotoMetadataModule.getPhotoMetadata,
  getEvent: EventModule.getModel,
  resetEventPhotos: EventPhotosModule.resetModels
}

const mapStateToProps = ({authenticatedUser, event, eventPhotos, pagination, photoMetadataMap}, ownProps) => {

  const eventCode = ownProps.match.params.code

  return {
    authenticatedUser,
    pagination: pagination.getPagination(PaginationContext.EVENTPHOTOS),
    eventCode,
    event,
    eventPhotos,
    photoMetadataMap
  }
}

export default withNamespaces('event', {wait: true})(
  connect(mapStateToProps, mapActionCreators)(PresentEventPhotosView)
)
