import UsersContainer from './containers/UsersContainer'
import AdminLayout from './layouts/AdminLayout'

export default {
  path: '/admin',
  component: AdminLayout,
  childRoutes: [
    {
      path: '/admin/users',
      component: UsersContainer
    }
  ]
}
