import * as React from 'react'
import './HomeView.less'
import {Panel} from 'react-bootstrap'
import {stringify} from 'qs'
import PhotoGrid from '../../../components/PhotoGrid/PhotoGrid'
import StatusSelect from '../../../components/Select/StatusSelect'
import Select from '../../../components/Select/Select'
import Config from '../../../config'
import {CriteriaKey} from '../../../models/Criteria'

interface Props {
  t
  tReady
  i18n
  updateCriteria
  getEventPhotosByCriteria
  getEventUsers
  getEvents
  eventPhotosPagination
  eventsPagination
  eventUsersPagination
  eventPhotos
  eventUsers
  events
  criteria
  location
  resetEvents
  resetEventPhotos
  resetEventUsers
  authenticatedUser
}

interface State {
  open: any
}

export default class HomeView extends React.Component<Props, State> {

  state = {
    open: true,
  }

  componentDidMount() {
    const {updateCriteria, location, getEvents, criteria} = this.props

    updateCriteria(location, criteria)
    getEvents()
  }

  componentDidUpdate(prevProps) {

    const {
      eventPhotos, events, eventsPagination,
      getEvents, getEventUsers, eventUsers, eventUsersPagination,
      location, criteria, updateCriteria} = this.props

    if (prevProps.events.isLoading && !events.isLoading) {

      if (eventsPagination.hasMore) {
        getEvents()
      } else {
        getEventUsers(criteria, events)
      }
    }

    if (prevProps.eventUsers.isLoading && !eventUsers.isLoading) {

      if (eventUsersPagination.hasMore) {
        getEventUsers(criteria, events, false)
      }
    }

    if (!prevProps.criteria.isEqual(criteria) && !eventPhotos.isLoading) {
      updateCriteria(location, criteria, false)
      getEventUsers(criteria, events, true)
    }
  }

  loadMore = () => {
    const {getEventPhotosByCriteria} = this.props

    getEventPhotosByCriteria(this.props.criteria)
  }

  onListCriteriaChange = (listName, newItems) => {

    const {criteria, getEventUsers, events} = this.props
    const list = criteria.get(listName).clear()

    const newCriteria = criteria.set(listName, list.concat(newItems))

    if (listName === CriteriaKey.EVENTID) {
      getEventUsers(newCriteria, events)
    }

    this.onCriteriaChange(newCriteria)
  }

  onSelectChange = (key, selectedIds) => {

    this.onListCriteriaChange(key, selectedIds)
  }

  onCriteriaChange = (criteria) => {
    const {updateCriteria, location} = this.props

    updateCriteria(location, criteria)
  }

  getSelectedOptions = (field: string) => {

    return this.props.criteria.get(field).toArray()
  }

  onPanelToggle = () => {
    this.setState({open: !this.state.open})
  }

  renderStatusSelect = () => {

    const {t} = this.props

    return (
      <StatusSelect
        t={t}
        onChange={this.onSelectChange.bind(this, CriteriaKey.STATUS)}
        selectedOptions={this.getSelectedOptions(CriteriaKey.STATUS)}
        label={t('status')}/>
    )
  }

  renderEventSelect = () => {

    const {t, events} = this.props

    const modelToOption = (model) => {
      return {
        label: `${model.code} - ${model.name}`,
        value: model.id,
      }
    }

    return (
      <Select
        isClearable={true}
        isMulti={true}
        placeholder={t('placeholder.selectEvents')}
        models={events.list.toArray()}
        isLoading={events.isLoading}
        onChange={this.onSelectChange.bind(this, CriteriaKey.EVENTID)}
        selectedOptions={this.getSelectedOptions(CriteriaKey.EVENTID)}
        modelToOption={modelToOption}
        label={t('event')}
        t={t}
      />
    )
  }

  renderUserSelect = () => {

    const {t, eventUsers} = this.props

    const modelToOption = (model) => {

      const name = model.profile.nickname || t('role.anonymous')
      const email = model.email ? `(${model.email})` : ''

      return {
        label: `${name} ${email}`,
        value: model.id,
      }
    }

    return (
      <Select
        isClearable={true}
        isMulti={true}
        placeholder={t('placeholder.selectUsers')}
        models={eventUsers.list.toArray()}
        isLoading={eventUsers.isLoading}
        onChange={this.onSelectChange.bind(this, CriteriaKey.USERID)}
        selectedOptions={this.getSelectedOptions(CriteriaKey.USERID)}
        modelToOption={modelToOption}
        label={t('user')}
        t={t}
      />
    )
  }

  renderFilterComponent = () => {

    const {t} = this.props

    return (
      <div className="photos-filter">
        <Panel expanded={this.state.open}
               defaultExpanded={this.state.open}
               onToggle={this.onPanelToggle}>
          <Panel.Heading onClick={this.onPanelToggle}>
            <span className="photos-filter__header">{t('filter')}</span>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <div className="filter">
                {this.renderStatusSelect()}
              </div>
              <div className="filter">
                {this.renderEventSelect()}
              </div>
              <div className="filter">
                {this.renderUserSelect()}
              </div>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }

  renderPhotoGrid = () => {

    const {error, isLoading, list} = this.props.eventPhotos

    const eventPhotoList = list.toJS()

    const photoData = eventPhotoList.map(photo => {

      const query = {
        token: this.props.authenticatedUser.accessToken,
        size: 'small'
      }

      const queryParams = stringify(query)

      const photoUrl = `${Config.API_URL}/photo/${photo.id}?${queryParams}`

      return {
        key: photo.id,
        src: photoUrl,
        alt: photo.filename
      }
    })

    return (
      <PhotoGrid
        filterComponent={this.renderFilterComponent()}
        scroll={true}
        loadMore={this.loadMore}
        hasMore={this.props.eventPhotosPagination.hasMore}
        photoData={photoData}
        loading={isLoading}
        t={this.props.t}
        error={error}/>
    )
  }

  render() {

    return (
      <div className="photo-event">
        {this.renderPhotoGrid()}
      </div>
    )
  }

  componentWillUnmount() {

    const {resetEventPhotos, resetEvents, resetEventUsers} = this.props

    resetEventPhotos()
    resetEvents()
    resetEventUsers()
  }
}
