import * as React from 'react'
import ErrorElement from '../../../../components/ErrorElement'

const logo = require('../../../../assets/images/generic-logo.svg')

interface Props {
  onSubmit
  isProcessing
  error
}

class LoginForm extends React.Component<Props, any> {

  public identity: HTMLInputElement
  public password: HTMLInputElement
  // does some basic checking to make sure identity & password are fileld in.
  handleChange = () => {
    const identity = this.identity.value
    const password = this.password.value

    if (identity && password) {
      this.setState({
        validForm: true
      })
    }
  }

  // don't allow form submission unless form is valid
  handleSubmit = (event) => {
    event.preventDefault()
    const identity = this.identity.value
    const password = this.password.value
    this.props.onSubmit(identity, password)
  }
  setIdentity = (input) => {
    this.identity = input
  }
  setPassword = (input) => {
    this.password = input
  }
  getError = () => {

    const {error} = this.props

    if (error) {
      return (
        <ErrorElement error={error.errorCode}/>
      )
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      validForm: false
    }
  }

  render() {
    return (
      <div className="login">
        <img className="logo" src={logo}/>
        <form name="loginForm" id="loginForm" method="POST" onChange={this.handleChange}
              onSubmit={this.handleSubmit}>
          <input type="text" name="identity" id="identity" ref={this.setIdentity} className="form-control"
                 placeholder="Username" required={true}/>
          <input type="password" name="password" id="password" ref={this.setPassword} className="form-control"
                 placeholder="Password" required={true}/>
          {this.getError()}
          <button className={`btn btn-primary btn-block ${this.state.validForm ? '' : 'disabled'}`}>
            {this.props.isProcessing ? <i className="fa fa-spin fa-spinner"/> : 'Login'}
          </button>
        </form>
      </div>
    )
  }
}

export default LoginForm
