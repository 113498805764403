/**
 * Created by Mauritz Untamala on 16/02/2017.
 */
import {Record} from 'immutable'

const State = Record({
  app: undefined,
  notifier: undefined,
  authenticatedUser: undefined,
  user: undefined,
  users: undefined,
  event: undefined,
  events: undefined,
  eventPhotos: undefined,
  eventUsers: undefined,
  photoMetadataMap: undefined,
  location: undefined,
  pagination: undefined,
  router: undefined
})

export default State
