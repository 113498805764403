'use strict'
/**
 * Created by Mauritz Untamala on 31/03/2017.
 */
import * as React from 'react'
import './Spinner.less'

type Size = 'big' | 'small'

interface Props {
  size?: Size
}

const Spinner = (props: Props) => {

  const {size} = props

  const className = ['spinner', 'fa fa-spin fa-spinner']

  switch (size) {
    case 'big':
      className.push('spinner--big')
      break
    case 'small':
      className.push('spinner--small')
      break
  }

  return (
      <i className={className.join(' ')}/>
  )
}

export default Spinner
