import BaseModel from './BaseModel'

const constraints = {
  id: {
    presence: true
  }
}

const interestedKeys = ['id', 'name', 'code', 'email', 'createdAt', 'updatedAt', 'deletedAt']

const defaultValues = {
  name: undefined,
  code: undefined,
  email: undefined
}

export default class Event extends BaseModel(defaultValues, interestedKeys, constraints)<Event> {

  name: string
  code: string
  email: string

  fromJS(js): Event {

    return new Event(js)
  }

  constructor(js?) {
    super(js)
  }
}
