import AuthLayout from '../../layouts/AuthLayout'
import LoginView from './containers/LoginView'
import LogoutView from './containers/LogoutView'

export default {
  path: ['/login', '/logout'],
  component: AuthLayout,
  childRoutes: [
    {path: '/login', component: LoginView},
    {path: '/logout', component: LogoutView}
  ]
}
