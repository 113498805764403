/**
 * Created by Mauritz Untamala on 30/05/2017.
 */
import * as React from 'react'

interface Props {
  partnerId: number
}

export default class PartnerUsers extends React.Component<Props, any> {

  render() {
    return <div>Partner id {this.props.partnerId} users</div>
  }
}
