import Event from '../models/Event'
import ModelModule from './ModelModule'

const initialState = new Event()

export class EventModule extends ModelModule<Event> {

  constructor() {
    super('event', initialState)
  }
}

export default new EventModule()
