/**
 * Created by Mauritz Untamala on 01/03/2017.
 */
import {Record} from 'immutable'

const App = Record({
  initializing: true,
  initialized: false,
  activeRestaurantId: undefined,
  error: undefined
})

export default App
