import 'isomorphic-fetch'
import * as urlJoin from 'url-join'
import * as Promise from 'bluebird'
import Config from '../config'
import {stringify} from 'qs'

export function getHeaders(token) {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  if (token) {
    headers['Authorization'] = 'Bearer ' + token
  }

  return headers
}

export default {

  request: (method, path, payload, token) => {

    return fetch(urlJoin(Config.API_URL, path), {
      method,
      headers: getHeaders(token),
      body: JSON.stringify(payload)
    }).then((res) => {

      return res.json().then(json => ({json, res}))

    }).then(({json, res}) => {

      if (!res.ok) {
        return Promise.reject(json)
      }

      return json
    })
  },
  get: (path, token, queryParams?) => {

    if (queryParams) {
      path = path + '?' + stringify(queryParams)
    }

    return fetch(urlJoin(Config.API_URL, path), {
      method: 'GET',
      headers: getHeaders(token)
    }).then((res) => {

      return res.json().then(json => ({json, res}))

    }).then(({json, res}) => {

      if (!res.ok) {
        return Promise.reject(json)
      }

      return json
    })
  },
  post: (path, payload, token?) => {

    return fetch(urlJoin(Config.API_URL, path), {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(payload)
    }).then((res) => {

      return res.json().then(json => ({json, res}))

    }).then(({json, res}) => {

      if (!res.ok) {
        return Promise.reject(json)
      }

      return json
    })
  },
  put: (path, payload, token) => {

    return fetch(urlJoin(Config.API_URL, path), {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(payload)
    }).then((res) => {

      return res.json().then(json => ({json, res}))

    }).then(({json, res}) => {

      if (!res.ok) {
        return Promise.reject(json)
      }

      return json
    })
  },
  delete: (path, token) => {

    return fetch(urlJoin(Config.API_URL, path), {
      method: 'DELETE',
      headers: getHeaders(token)
    }).then((res): any => {

      if (!res.ok) {

        return res.json()
          .then(json => {
            throw json
          })
      }

      return true
    })
  }
}
