import User from '../models/User'
import ModelModule from './ModelModule'

const initialState = new User()

export class UserModule extends ModelModule<User> {

  constructor() {
    super('user', initialState)
  }
}

export default new UserModule()
