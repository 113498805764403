import * as React from 'react'
import './AdminLayout.less'

class AdminLayout extends React.Component<any, any> {

  render() {
    return (
      <div className="admin-layout">
        {this.props.children}
      </div>
    )
  }
}

export default AdminLayout
