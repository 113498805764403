import EventPhotos from '../models/EventPhotos'
import ModelsModule from './ModelsModule'
import {PaginationContext} from '../models/Pagination'

const initialState = new EventPhotos()

class EventPhotosModule extends ModelsModule {

  constructor() {
    super(
      'eventPhotos',
      initialState,
      {path: 'photo', paginationContext: PaginationContext.EVENTPHOTOS, pageSize: 10}
    )
  }
}

export default new EventPhotosModule()
