import {Record} from 'immutable'

interface FaceRecognitionResult {
  faceId: string
  location: {
    top: number
    right: number
    bottom: number
    left: number
  }
  confidence: number
}

interface PhotoSize {
  width: number
  height: number
}

const defaultValues = {
  sender: undefined,
  originalSize: undefined,
  facePredictions: undefined
}

class PhotoMetadata extends Record(defaultValues) {
  sender?: string
  originalSize?: PhotoSize
  facePredictions?: FaceRecognitionResult[]

  constructor(js?) {
    super(js)
  }
}

export default PhotoMetadata
