import * as qs from 'qs'
import * as React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {UserIsNotAuthenticated} from '../../../config/policies'
import AuthenticatedUserModule from '../../../modules/AuthenticatedUser'
import LoginForm from '../components/LoginForm'

const mapActionCreators = {
  login: AuthenticatedUserModule.login
}

const mapStateToProps = (state, ownProps) => {
  const redirect =
    ownProps.history && ownProps.history.location
      ? qs.parse(ownProps.history.location.search).redirect
      : '/'
  return {
    user: state.authenticatedUser,
    redirect,
    history: ownProps.history
  }
}

interface Props {
  user: any
  login
  redirect
  history
}

class LoginView extends React.Component<Props, any> {
  _handleLogin = (identity, password) => {
    this.props.login(
      identity,
      password,
      this.props.redirect,
      this.props.history
    )
  }

  render() {
    const {user} = this.props
    return (
      <div className="container">
        <LoginForm
          onSubmit={this._handleLogin}
          isProcessing={user && user.isLoading}
          error={user.error}
        />
      </div>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapActionCreators
  )(UserIsNotAuthenticated(LoginView))
)
