import * as React from 'react'
import {Notifications} from 'react-redux-notifier'
import './CoreLayout.less'

export const CoreLayout = ({children}) => (
  <div className="core-layout">
    {children}
    <Notifications nKey="core"/>
  </div>
)

export default CoreLayout
