import * as React from 'react'
import './PresentEventPhotosView.less'
import {UserIsAuthenticated} from '../../../config/policies'
import Config from '../../../config'
import {stringify} from 'querystring'
import Image from '../../../components/Image/Image'
import EventPhoto from '../../../models/EventPhoto'
import {List} from 'immutable'
import * as _ from 'lodash'
import EventPhotos from '../../../models/EventPhotos'
import PhotoMetadata from '../../../models/PhotoMetadata'

const placeholder = require('../../../assets/images/placeholder.jpg')

interface Props {
  authenticatedUser
  pagination
  eventPhotos
  eventCode
  getEvent
  getPhotos
  event,
  getPhotoMetadata,
  photoMetadataMap,
  t
}

@UserIsAuthenticated
export default class PresentEventPhotosView extends React.Component<Props, any> {

  private mounted = false
  private fetchNewPhotosInterval
  private static readonly previewCount = 5

  constructor(props) {
    super(props)

    this.state = {initialLoadDone: false, displayQueue: []}
  }

  componentDidMount() {

    const {getEvent, eventCode, getPhotos} = this.props

    getEvent(eventCode)
    getPhotos({eventCode, columnName: 'createdAt', sort: 'asc'}, true)
    this.mounted = true
  }

  componentWillUnmount() {

    this.mounted = false
    clearInterval(this.fetchNewPhotosInterval)
  }

  componentDidUpdate(prevProps, prevState) {

    const {getPhotos, eventCode, eventPhotos, pagination} = this.props

    if (prevProps.eventPhotos.isLoading && !eventPhotos.isLoading) {

      if (pagination.hasMore) {

        getPhotos({eventCode, columnName: 'createdAt', sort: 'asc'})

      } else if (!this.state.initialLoadDone) {

        const latestPhoto = eventPhotos.list.last()
        const photos = _.shuffle(eventPhotos.list.toArray())
        const displayQueue = photos.slice(0, PresentEventPhotosView.previewCount + 1)
        const photoQueue = photos.slice(PresentEventPhotosView.previewCount + 1)

        this.setState({
          initialLoadDone: true,
          displayQueue,
          photoQueue,
          latestPhoto
        })

      } else {

        this.adjustQueuesAfterFetch(eventPhotos)
      }
    }

    if (this.state.initialLoadDone && !prevState.initialLoadDone) {

      this.fetchNewPhotosInterval = setInterval(this.fetchNewPhotos, 10000)
    }
  }

  adjustQueuesAfterFetch = (eventPhotos: EventPhotos) => {

    let {displayQueue, photoQueue, latestPhoto} = this.state
    const newPhotos = this.resolveNewPhotos(eventPhotos.list).toArray()

    // photo queue is empty. Issue a new randomized photo queue without the new or display queue photos
    if (photoQueue.length === 0) {

      const excludedIds = displayQueue.map(p => p.id).concat(newPhotos.map(p => p.id))
      const photosWithoutExcluded = eventPhotos.list.filter(p => !_.includes(excludedIds, p.id)).toArray()

      photoQueue = _.shuffle(photosWithoutExcluded)
    }

    const removedPhoto = displayQueue.shift() // remove current photo

    while (photoQueue.length > 0 && displayQueue.length < (PresentEventPhotosView.previewCount + 1)) {
      displayQueue.push(photoQueue.pop()) // add top entry from photo queue to display queue
    }

    // Add new photos to tail of display queue
    if (newPhotos.length > 0) {
      displayQueue = displayQueue.concat(newPhotos)
      latestPhoto = _.last(newPhotos)
    }

    // If display queue is below desired size add the removed photo to it
    if (removedPhoto && displayQueue.length < (PresentEventPhotosView.previewCount + 1)) {
      displayQueue.push(removedPhoto)
    }

    this.setState({
      displayQueue,
      photoQueue,
      latestPhoto
    })
  }

  resolveNewPhotos = (eventPhotos: List<EventPhoto>): List<EventPhoto> => {

    const {latestPhoto} = this.state
    const latestHeadIndex = eventPhotos.indexOf(latestPhoto)

    if (latestHeadIndex === -1) {
      return eventPhotos
    }

    return eventPhotos.slice(latestHeadIndex + 1) as List<EventPhoto>
  }

  renderPhotoSender = (photo) => {

    if (!photo) {
      return undefined
    }

    const {metadata} = photo
    const uploader = metadata && metadata.sender ? metadata.sender : 'anonymous'

    return <span>{this.props.t('uploadedBy', {value: uploader})}</span>
  }

  renderInstructions = () => {

    const {event, t} = this.props

    if (event && event.email) {
      return <span>{t('eventEmail')}<b> {event.email}</b></span>
    }
  }

  fetchNewPhotos = () => {

    if (!this.mounted) {
      return
    }

    const {eventCode, getPhotos} = this.props

    getPhotos({eventCode, columnName: 'createdAt', sort: 'asc'})
  }

  createPhotoUrl = (photo: EventPhoto, size: ('small' | 'large')) => {

    const {authenticatedUser} = this.props

    const query = {
      token: authenticatedUser.accessToken,
      size
    }

    const queryParams = stringify(query)

    return `${Config.API_URL}/photo/${photo.id}?${queryParams}`
  }

  renderPhoto = (photo: EventPhoto, size: ('small' | 'large'), photoMetadata?: PhotoMetadata) => {
    const {getPhotoMetadata} = this.props
    const photoUrl = this.createPhotoUrl(photo, size)

    return (
      <Image
        photoUrl={photoUrl}
        alt={photo.filename}
        photoMetadata={photoMetadata}
        id={photo.id}
        getPhotoMetadata={getPhotoMetadata}
      />
    )
  }

  renderCurrentPhoto = (photo) => {

    if (!photo) {
      return <Image photoUrl={placeholder} alt="placeholder image"/>
    }

    const {photoMetadataMap} = this.props
    const photoMetadata = photoMetadataMap.get(`${photo.id}`)

    return this.renderPhoto(photo, 'large', photoMetadata)
  }

  renderNextPhoto = (photo) => {
    if (!photo) {
      return undefined
    }

    const photoUrl = this.createPhotoUrl(photo, 'small')

    return <img key={`preview-${photo.id}`} src={photoUrl} alt={photo.filename}/>
  }

  renderNextPhotos = (nextPhotos: EventPhoto[]) => {

    if (_.isEmpty(nextPhotos)) {
      return undefined
    }

    return (
      <>
        <div className="title-element">{this.props.t('nextPhotosTitle', {value: nextPhotos.length})}</div>
        {nextPhotos.map(this.renderNextPhoto)}
      </>
    )
  }

  render() {

    const {displayQueue} = this.state
    const current = _.first(displayQueue)
    const nextPhotos = displayQueue.slice(1, PresentEventPhotosView.previewCount + 1)

    return (
      <div className="present-event-photo">
        <div className="current-photo">{this.renderCurrentPhoto(current)}</div>
        <div className="next-photos">{this.renderNextPhotos(nextPhotos)}</div>
        <div className="sender">{this.renderPhotoSender(current)}</div>
        <div className="instructions">{this.renderInstructions()}</div>
      </div>
    )
  }
}
