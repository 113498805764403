import {List, Record} from 'immutable'
import {generateUUID, isEqual} from '../utils'
import {endOfLoading, endOfSaving, setDateFields, startOfLoading, startOfSaving} from './Common'
import ModelInterface from './ModelInterface'
import * as _ from 'lodash'

const _validate = require('validate.js')

export default function BaseModel(defaults, equalsKeys = ['id'], constraints = {}) {

  const baseConstraints = {
    _id: {
      presence: {allowEmpty: false}
    }
  }

  const baseDefaults = {
    id: undefined,
    _id: undefined,
    editing: false,
    isLoading: false,
    isSaving: false,
    error: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    deletedAt: undefined
  }

  const withBaseDefault = _.merge(baseDefaults, defaults)
  const withBaseConstraints = _.merge(baseConstraints, constraints)

  abstract class BaseModel<T extends ModelInterface<T>> extends Record(withBaseDefault) implements ModelInterface<T> {

    _id: string
    id: number
    editing: boolean
    isLoading: boolean
    isSaving: boolean
    error: any

    constructor(js = {}) {
      super(_.merge({_id: generateUUID()}, js))

      return setDateFields(this)
    }

    abstract fromJS(js): T

    getId() {

      return this.id || this._id
    }

    setIdentityFrom(model: T): T {

      return this.set('id', model.id)
        .set('_id', model._id) as any
    }

    identityEquals(model: T) {

      return (this.id && this.id === model.id) || (this._id && this._id === model._id)
    }

    hasIdentity(id) {

      return this.id === id || this._id === id
    }

    startOfLoading() {

      return startOfLoading(this)
    }

    endOfLoading(error) {

      return endOfLoading(this, error)
    }

    startOfSaving() {

      return startOfSaving(this)
    }

    endOfSaving(error) {

      return endOfSaving(this, error)
    }

    isEqual(otherObject) {

      return isEqual(equalsKeys, this.toJS(), otherObject.toJS())
    }

    validate() {

      return this._validate(withBaseConstraints)
    }

    isValid() {

      return !this.validate()
    }

    protected _validate(validationConstraints) {

      return _validate(this.toJS(), validationConstraints, {fullMessages: false})
    }

    protected setListArray(listArray: Array<string | object>, js?): BaseModel<T> {

      if (!js) {
        return this as BaseModel<T>
      }

      let model = this as BaseModel<T>

      listArray.forEach(listName => {

        if (_.isObject(listName)) {

          const resolvedListName = Object.keys(listName)[0]
          const func = listName[resolvedListName]
          model = model.set(resolvedListName, List((js[resolvedListName] || []).map(func))) as BaseModel<T>

        } else {

          model = model.set(listName as string, List(js[listName as string] || [])) as BaseModel<T>
        }
      })

      return model
    }
  }

  return BaseModel
}
