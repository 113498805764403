import {combineReducers} from 'redux-immutable'
import {connectRouter} from 'connected-react-router/immutable'
import {Reducer as notifier} from 'react-redux-notifier'
import app from '../modules/App'
import user from '../modules/User'
import users from '../modules/Users'
import event from '../modules/Event'
import events from '../modules/Events'
import eventUsers from '../modules/EventUsers'
import authenticatedUser from '../modules/AuthenticatedUser'
import eventPhotos from '../modules/EventPhotos'
import location from '../modules/Location'
import pagination from '../modules/Pagination'
import photoMetadataMap from '../modules/PhotoMetadataMap'

export const makeRootReducer = (history, asyncReducers?: any) => {
  return combineReducers({
    app,
    user: user.getReducer(),
    users: users.getReducer(),
    authenticatedUser: authenticatedUser.getReducer(),
    event: event.getReducer(),
    events: events.getReducer(),
    eventPhotos: eventPhotos.getReducer(),
    eventUsers: eventUsers.getReducer(),
    photoMetadataMap: photoMetadataMap.getReducer(),
    router: connectRouter(history),
    notifier,
    location,
    pagination,
    ...asyncReducers
  })
}

export const injectReducer = (store: any, {key, reducer}: any) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
