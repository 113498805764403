import ModelsModule from './ModelsModule'
import {get} from './ActionsCommon'
import {createAction} from 'redux-actions'
import {bind} from '../utils'
import {Map} from 'immutable'

const initialState = Map({})

class PhotoMetadataMapModule extends ModelsModule {
  private getPhotoMetadataSuccessType: string
  private getPhotoMetadataSuccessAction: any

  constructor() {
    super(
      'photoMetadataMap',
      initialState,
      {path: 'photo'}
    )
    bind(this, this.getPhotoMetadata)
  }

  public getPhotoMetadata(id) {
    return (dispatch) => {
      return dispatch(get(this.path, [id, 'metadata']))
        .then((metadata) => dispatch(this.getPhotoMetadataSuccessAction({id, metadata})))
    }
  }

  getAdditionalActionHandlers() {
    return {
      [this.getPhotoMetadataSuccessType]: (state, {payload: {id, metadata}}) => state.setIn([`${id}`], metadata)
    }
  }

  protected initializeTypes() {
    super.initializeTypes()
    this.getPhotoMetadataSuccessType = `${this.name}.GET_PHOTO_METADATA_SUCCESS`
  }

  protected initializeActions() {
    super.initializeActions()
    this.getPhotoMetadataSuccessAction = createAction(this.getPhotoMetadataSuccessType)
  }
}

export default new PhotoMetadataMapModule()
