/**
 * Created by Mauritz Untamala on 30/05/2017.
 */
import HomeView from '../components/HomeView'
import {withNamespaces} from 'react-i18next'
import EventPhotosModule from '../../../modules/EventPhotos'
import EventUsersModule from '../../../modules/EventUsers'
import EventsModule from '../../../modules/Events'
import {connect} from 'react-redux'
import {PaginationContext} from '../../../models/Pagination'
import Criteria from '../../../models/Criteria'

const mapActionCreators = {
  updateCriteria: EventPhotosModule.updateCriteria,
  getEventPhotosByCriteria: EventPhotosModule.getModelsByCriteria,
  resetEventPhotos: EventPhotosModule.resetModels,
  getEvents: EventsModule.getModels,
  getEventUsers: EventUsersModule.fetchEventUsers,
  resetEventUsers: EventUsersModule.resetModels,
  resetEvents: EventsModule.resetModels,
}

const mapStateToProps = ({eventPhotos, pagination, authenticatedUser, eventUsers, events}, ownProps) => {

  const criteria = Criteria.getCriteriaWithoutInitialDateRage(ownProps.location)

  return {
    eventPhotosPagination: pagination.getPagination(PaginationContext.EVENTPHOTOS),
    eventsPagination: pagination.getPagination(PaginationContext.EVENTS),
    eventUsersPagination: pagination.getPagination(PaginationContext.EVENTUSERS),
    eventPhotos,
    criteria,
    events,
    eventUsers,
    authenticatedUser
  }
}

export default withNamespaces(['common'], {wait: true})(
  connect(mapStateToProps, mapActionCreators)(HomeView)
)
