import * as _ from 'lodash'
import * as moment from 'moment'
import * as uuid from 'uuid'

export function generateUUID() {
  return uuid.v4()
}

export const formatDate = (date) => {

  return moment(date).format('lll')
}

export function bindOn(obj) {
  Object.getOwnPropertyNames(Object.getPrototypeOf(obj))
    .filter(name => name.indexOf('on') === 0)
    .forEach(name => {
      if (obj[name]) {
        obj[name] = obj[name].bind(obj)
      }
    })
}

export function bind(obj, ...methods) {
  methods.forEach(method => {
    if (method.name !== '' && !!obj[method.name]) {
      obj[method.name] = obj[method.name].bind(obj)
    } else {
      const name: any = Object.getOwnPropertyNames(Object.getPrototypeOf(obj))
        .find(name => obj[name] === method)
      if (obj[name]) {
        obj[name] = obj[name].bind(obj)
      }
    }
  })
}

export const reduceSum = (accu, value) => accu + value

export const hasPath = (location, path) => {
  return location && location.pathname && pathnameHasFragment(location.pathname, path)
}

export const pathnameHasFragment = (pathname, path) => {

  if (!pathname) {
    return false
  }

  return !!pathname.split('?')[0].split('/').find(fragment => fragment === path)
}

const TIME_REGEXP = /^(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,4}))?\+(\d{2,4})$/

export const isTimeTz = (time) => {

  return TIME_REGEXP.test(time)
}

export const parseTimeTzToMomentNow = (time) => {

  const match = TIME_REGEXP.exec(time)

  if (!match) {
    return null
  }

  const toThreeDigitMillis = (value) => {

    const num = parseInt(value, 10)
    const length = value && value.length

    switch (length) {
      case 3:
        return num
      case 2:
        return num * 10
      case 1:
        return num * 100
      default:
        return 0
    }
  }

  return moment()
    .hour(parseInt(match[1], 10))
    .minute(parseInt(match[2], 10))
    .second(parseInt(match[3], 10))
    .millisecond(toThreeDigitMillis(match[4]))
    .utcOffset(match[5])
}

export const dateToTimeTz = (date) => {

  return moment(date).format('HH:mm:ss.SSSZZ')
}

export function isEqual(interestedKeys, object, otherObject) {

  return _.isMatchWith(object, otherObject, (value, other, key) => {

    if (_.includes(interestedKeys, key)) {

      if (moment.isMoment(value)) {
        return value.isSame(other)
      }

      value = _.isUndefined(value) ? null : value
      other = _.isUndefined(other) ? null : other

      return _.isEqual(value, other)
    }
    return true
  })
}

export function toArray(array) {

  if (_.isEmpty(array)) {

    return []
  }

  if (!_.isArray(array)) {

    array = [array]
  }

  return array.map(value => {

    const intValue = parseInt(value, 10)

    if (isNaN(intValue)) {
      return value
    }

    return intValue
  })
  return array.map(value => parseInt(value, 10))
}

export function stringToArray(values) {

  if (_.isEmpty(values)) {

    return []
  }

  if (_.isArray(values)) {

    return toArray(values)

  } else {

    values = values.split(',')
    return toArray(values)
  }
}

export const datesEqual = (date1, date2) => moment(date1).isSame(moment(date2), 'minute')
