/**
 * Created by Mauritz Untamala on 30/05/2017.
 */
import {connect} from 'react-redux'
import Users from '../components/Users'

const mapActionCreators = {}

const mapStateToProps = (_state, props) => {

  const partnerId = props.params.id ? parseInt(props.params.id, 10) : null

  return {partnerId}
}

export default connect(mapStateToProps, mapActionCreators)(Users)
