import * as React from 'react'
import Select from 'react-select'
import * as _ from 'lodash'
import './Select.less'

interface Props {
  selectedOptions
  onChange
  t
  options?
  isClearable?: boolean
  isMulti?: boolean
  className?: string
  styles?
  placeholder?: string
  models?
  modelToOption?
  isLoading?: boolean
  label?: string
}

const disabledColor = '#ccc'
const textColor = '#000000'

class SelectWrapper extends React.Component<Props, any> {

  private static readonly customTheme = (theme) => ({
    ...theme,
    spacing: {
      baseUnit: 2,
      controlHeight: 32
    }
  })

  onChange = (selectedOptions) => {

    const {onChange} = this.props
    const selectedIds = _.isArray(selectedOptions) ? _.map(selectedOptions, 'value') : selectedOptions.value

    onChange(selectedIds)
  }

  getSelectedOptions = (options) => {

    const selectedOptions = this.props.selectedOptions

    return (_.isArray(selectedOptions) ? selectedOptions : [selectedOptions])
      .map(id => options.find(t => t.value === id))
  }

  modelToOption = model => {

    if (this.props.modelToOption) {

      return this.props.modelToOption(model)
    }

    return {
      label: model.name,
      value: model.id,
    }
  }

  getOptions = (models) => {

    return models.map(this.modelToOption)
  }

  getStyles = () => {

    return {
      option: (styles, {isDisabled}) => {
        return {
          ...styles,
          color: isDisabled
            ? disabledColor
            : textColor,
          cursor: isDisabled ? 'not-allowed' : 'default',
        }
      },
      multiValue: (styles) => {
        return {
          ...styles,
          backgroundColor: 'transparent',
        }
      },
      multiValueRemove: (styles) => ({
        ...styles,
        ':hover': {
          backgroundColor: 'transparent',
        },
      }),
    }
  }

  render() {

    const {options, models, isClearable, isMulti, className, styles, placeholder, t, isLoading, label} = this.props

    const resolvedOptions = options || this.getOptions(models)

    const selectedOptions = this.getSelectedOptions(resolvedOptions)

    return (
      <div className="select">
        <div className="select__label">
          <label>{label}</label>
        </div>
        <div className="select__content">
          <Select
            className={'react-select-container' + (className ? ' ' + className : '')}
            classNamePrefix="react-select"
            isClearable={isClearable}
            isMulti={isMulti}
            options={resolvedOptions}
            value={selectedOptions}
            onChange={this.onChange}
            placeholder={placeholder || t('placeholder.select')}
            styles={styles || this.getStyles()}
            isLoading={isLoading}
            theme={SelectWrapper.customTheme}
          />
        </div>
      </div>
    )
  }
}

export default SelectWrapper
