import * as React from 'react'
import {connect, Provider} from 'react-redux'
import {initialize} from '../modules/App'
import Routes from '../routes'
import {History} from 'history'
import {ConnectedRouter} from 'connected-react-router/immutable'

const mapActionCreators = {
  initialize
}

export interface Props {
  store: any
  history: History
  initialize?: any
}

class AppContainer extends React.Component<Props, any> {

  componentWillMount() {
    this.props.initialize(this.props.history)
  }

  render() {
    const {store, history} = this.props

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
        {Routes}
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default connect<{}, {}, Props>(
  null,
  mapActionCreators
)(AppContainer)
