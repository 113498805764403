import * as React from 'react'

const faceLabelOffsetPercentage = 30 // The percentage the label is above the box "bottom: 130%" <- 30% above top
const defaultLabelHeightPercentage = 10

interface Props {
  originalSize: any
  facePrediction
}

interface State {
  labelHeight: number
  faceHeight: number
}

export default class FacePredictionBox extends React.Component<Props, State> {

  private boxRef

  constructor(props) {
    super(props)
    this.state = {
      labelHeight: undefined,
      faceHeight: undefined
    }
  }

  componentDidMount(): void {
    const {clientHeight, parentElement} = this.boxRef
    this.setState({labelHeight: clientHeight, faceHeight: parentElement.clientHeight})
  }

  isInvertedLabel(faceBoxHeightPercentage, faceBoxTopPercentage) {

    const {labelHeight, faceHeight} = this.state
    const labelHeightPercentage = labelHeight && faceHeight
      ? faceBoxHeightPercentage * (labelHeight / faceHeight)
      : defaultLabelHeightPercentage
    const labelOffsetPercentage = faceBoxHeightPercentage * faceLabelOffsetPercentage / 100
    const labelTopPercentage = labelHeightPercentage + labelOffsetPercentage

    return (faceBoxTopPercentage - labelTopPercentage) < 0
  }

  render() {

    const {originalSize: {width: originalWidth, height: originalHeight}} = this.props
    const {facePrediction: {faceId, location: {top, left, bottom, right}}} = this.props
    const faceBoxTopPercentage = (top / originalHeight) * 100
    const faceBoxHeightPercentage = ((bottom - top) / originalHeight) * 100

    const facePredictionBoxStyle: React.CSSProperties = {
      position: 'absolute',
      top: `${faceBoxTopPercentage}%`,
      left: `${(left / originalWidth) * 100}%`,
      width: `${((right - left) / originalWidth) * 100}%`,
      height: `${faceBoxHeightPercentage}%`,
      zIndex: 3,
      animation: 'fadeIn 1s ease-in'
    }

    const inverted = this.isInvertedLabel(faceBoxHeightPercentage, faceBoxTopPercentage)

    return (
      <div style={facePredictionBoxStyle}>
        <div className={`face-label-wrapper ${inverted ? 'inverted' : ''}`} ref={ref => this.boxRef = ref}>
          <div className="face-label-container">
            <div className="face-label">{faceId}</div>
            <span className="strip-container">
              <div className="strip-1"/>
              <div className="strip-2"/>
              <div className="strip-3"/>
              <div className="strip-4"/>
            </span>
          </div>
          <div className="face-pole"/>
        </div>
      </div>
    )
  }
}
