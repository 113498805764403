import Users from '../models/Users'
import ModelsModule from './ModelsModule'

const initialState = new Users()

class UsersModule extends ModelsModule {

  constructor() {
    super('users', initialState, {path: 'user'})
  }
}

export default new UsersModule()
