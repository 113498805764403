import * as React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import Header from '../../components/Header'
import {UserIsAuthenticated} from '../../config/policies'
import './AppLayout.less'

const mapStateToProps = ({authenticatedUser}, {history}) => {
  return {
    authenticatedUser,
    history
  }

}

interface Props {
  authenticatedUser
  history
}

class AppLayout extends React.Component<Props, any> {

  render() {

    return (
      <div className="app-layout">
        <div className="app-layout__header">
          <Header {...this.props} />
        </div>
        <div className="app-layout__content">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(UserIsAuthenticated(AppLayout)) as any)
