import * as React from 'react'
import Select from './Select'
import EventPhoto, {PhotoStatus} from '../../models/EventPhoto'
import chroma from 'chroma-js'

interface Props {
  selectedOptions
  t
  onChange
  label?: string
}

class StatusSelect extends React.Component<Props, any> {

  getPhotoStatusOptions = () => {
    const {t} = this.props

    const options = Object.keys(PhotoStatus).map(status => {
      return {
        value: PhotoStatus[status],
        label: t(`photoStatus.${PhotoStatus[status]}`)
      }
    })

    return options
  }

  onSelectChange = (selectedIds) => {

    this.props.onChange(selectedIds)
  }

  getStyles = () => {

    return {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const statusColor = EventPhoto.getColorByStatus(data.value)
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected ? EventPhoto.getColorByStatus(data.value)
            : isFocused ? chroma(statusColor).alpha(0.2).css() : null,
          color: isDisabled
            ? '#ccc'
              : statusColor,
          cursor: isDisabled ? 'not-allowed' : 'default',
        }
      },
      multiValue: (styles, { data }) => {
        const statusColor = EventPhoto.getColorByStatus(data.value)
        return {
          ...styles,
          backgroundColor: chroma(statusColor).alpha(0.2).css(),
        }
      },
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        'color': EventPhoto.getColorByStatus(data.value),
        ':hover': {
          backgroundColor: EventPhoto.getColorByStatus(data.value),
          color: 'white',
        },
      }),
    }
  }

  render() {

    const {t, label, selectedOptions} = this.props

    return (
      <Select
        t={t}
        isMulti={true}
        isClearable={true}
        onChange={this.onSelectChange}
        options={this.getPhotoStatusOptions()}
        selectedOptions={selectedOptions}
        styles={this.getStyles()}
        placeholder={t('placeholder.selectStatus')}
        label={label}
      />
    )
  }
}

export default StatusSelect
