import * as _ from 'lodash'
import BaseModel from './BaseModel'
import {List} from 'immutable'
import {stringToArray} from '../utils'
import * as queryString from 'query-string'

export enum CriteriaKey {
  EVENTID = 'eventId',
  STATUS = 'status',
  OWN = 'own',
  USERID = 'userId'
}

const interestedKeys = [
  'eventId',
  'status',
  'own',
  'userId'
]

const defaultValues = {
  eventId: List(),
  status: List(),
  own: undefined,
  userId: List()
}

const idLists = [CriteriaKey.STATUS, CriteriaKey.USERID, CriteriaKey.EVENTID]
const idFields = [CriteriaKey.OWN]

export default class Criteria extends BaseModel(defaultValues, interestedKeys)<Criteria> {

  eventId: List<number>
  own: undefined
  status: List<string>
  userId: List<number>

  static fromQuery(js: any = {}): Criteria {

    if (js) {
      idLists.forEach(field => {
        js[field] = stringToArray(js[field])
      })
    }

    return new Criteria(js)
  }

  static getCriteria = ({search}) => {

    const query = queryString.parse(search)

    const hasParams = !!_.find(Object.keys(query), (key) => key !== '')

    if (hasParams) {

      return Criteria.fromQuery(query)

    } else {

      return Criteria.getInitialCriteria()
    }
  }

  static getCriteriaWithoutInitialDateRage = (location) => {

    const {search} = location

    const query = queryString.parse(search)

    const hasParams = !!_.find(Object.keys(query), (key) => key !== '')

    if (hasParams) {

      return Criteria.fromQuery(query)

    } else {

      return Criteria.getInitialCriteria()
    }
  }

  static getInitialCriteria(js = {}) {

    return new Criteria(js)
  }

  constructor(js?) {
    super(js)

    if (js) {

      const criteria = this.setListArray(idLists, js) as Criteria

      return criteria
    }
  }

  fromJS(js: any = {}): Criteria {

    return new Criteria(js)
  }

  listToParams(queryParams, fields) {

    _.each(fields, (field) => {
      const list = this[field]

      if (list && !list.isEmpty()) {
        queryParams[field] = list.toArray()
      }
    })
  }

  fieldsToParams(queryParams, fields) {

    _.each(fields, (field) => {
      if (this[field] !== undefined) {
        queryParams[field] = this[field]
      }
    })
  }

  getQueryParams(fields?, fieldsList?) {

    const queryParams = {}

    this.listToParams(queryParams, fieldsList ? fieldsList : idLists)
    this.fieldsToParams(queryParams, fields ? fields : idFields)

    return queryParams
  }
}
