import AppLayout from '../../layouts/AppLayout'
import HomeViewContainer from './containers/HomeViewContainer'

// Sync route definition
export default {
  path: '/',
  exact: true,
  component: AppLayout,
  childRoutes: [{
    path: '/',
    exact: true,
    component: HomeViewContainer
  }]
}
