import { applyMiddleware, compose, createStore } from 'redux'
import makeRootReducer from './reducers'
import { Map } from 'immutable'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import {routerMiddleware} from 'connected-react-router'
/// ../window.d.ts
const appConfig: any = require('../config')

export default (
  initialState = Map(),
  history
): Redux.Store<any> => {

  const middlewares: Redux.Middleware[] = [thunk, routerMiddleware(history)]

  /** Add Only Dev. Middlewares */
  if (appConfig.env !== 'production' && process.env.BROWSER) {
    const logger = createLogger()
    middlewares.push(logger)
  }

  const composeEnhancers =
    (appConfig.env !== 'production' &&
      typeof window === 'object' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose
  const store = createStore(
    makeRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  if (appConfig.env === 'development' && (module as any).hot) {
    (module as any).hot.accept('./reducers', () => {
      store.replaceReducer(require('./reducers'))
    })
  }

  return store
}
