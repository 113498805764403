import Events from '../models/Events'
import ModelsModule from './ModelsModule'
import {PaginationContext} from '../models/Pagination'

const initialState = new Events()

class EventsModule extends ModelsModule {

  constructor() {
    super(
      'event',
      initialState,
      {paginationContext: PaginationContext.EVENTPHOTOS}
    )
  }
}

export default new EventsModule()
