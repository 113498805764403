import {createAction} from 'redux-actions'
import User from '../models/User'
import API from '../services/api'
import {destroyToken, retrieveToken, storeToken} from '../services/localStorage'
import {bind} from '../utils'
import ModelModule from './ModelModule'

const initialState = new User()

export class AuthenticatedUserModule extends ModelModule<User> {

  public authenticate = (history?) => {

    return dispatch => {

      dispatch(this.loginRequestAction())

      const accessToken = retrieveToken()

      if (accessToken) {

        return API.post('/auth/bearer', undefined, accessToken)
          .then(response => {

            dispatch(this.loginSuccessAction(response))

            return this.postAuthenticateSuccess(response, undefined, history)
          })
          .catch(error => {

            destroyToken()

            dispatch(this.loginFailedAction(error))

            throw error
          })
      } else {

        dispatch(this.loginFailedAction())

        return Promise.resolve(false)
      }
    }
  }
  private loginRequestType: string
  private loginSuccessType: string
  private loginFailedType: string
  private logoutRequestType: string
  private logoutSuccessType: string
  private authenticationRequestType: string
  private authenticationSuccessType: string
  private authenticationFailedType: string
  private loginRequestAction: any
  private loginSuccessAction: any
  private loginFailedAction: any
  private logoutRequestAction: any
  private logoutSuccessAction: any

  constructor() {
    super('authenticatedUser', initialState)
    bind(this,
      this.authenticate,
      this.login,
      this.logout
    )
  }

  public login(identifier, password, redirect = '/', history?) {
    return (dispatch) => {

      dispatch(this.loginRequestAction())

      return API.post('/auth/local', {identifier, password})
        .then(response => {
          storeToken(response.token)
          dispatch(this.loginSuccessAction(response))
          return this.postAuthenticateSuccess(response, redirect, history)
        })
        .catch(error => {
          dispatch(this.loginFailedAction(error))
        })
    }
  }

  public logout() {
    return (dispatch) => {
      dispatch(this.logoutRequestAction())

      return Promise.resolve(destroyToken())
        .then(() => dispatch(this.logoutSuccessAction()))
        .catch(error => dispatch(this.loginFailedAction(error)))
    }
  }

  public resetAuthenticatedUser() {

    return dispatch => dispatch(this.logoutSuccessAction())
  }

  public getLogoutSuccessType() {
    return this.logoutSuccessType
  }

  postAuthenticateSuccess(_response, redirect?, history?) {

    if (redirect && history) {

      const query = window.location.search
      redirect = '/' + (query || '')

      return history.push(redirect)
    } else {
      return null
    }
  }

  protected getAdditionalActionHandlers() {

    const setAuthenticatedUser = (state: User, {payload}) => {
      const {user, token} = payload
      const authenticatedUserPayload = {...user, accessToken: token}
      return state.fromJS(authenticatedUserPayload).setAuthenticated(true)
    }

    return {
      [this.authenticationRequestType]: (state) => state.startOfLoading(),
      [this.authenticationSuccessType]: setAuthenticatedUser,
      [this.authenticationFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.loginRequestType]: (state) => state.startOfLoading(),
      [this.loginSuccessType]: setAuthenticatedUser,
      [this.loginFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.logoutRequestType]: (state) => state.startOfLoading(),
      [this.logoutSuccessType]: () => initialState,
      [this.getModelSuccessType]: setAuthenticatedUser,
      [this.updateModelSuccessType]: setAuthenticatedUser
    }
  }

  protected initializeTypes() {
    super.initializeTypes()

    this.loginRequestType = `${this.name}.LOGIN_REQUEST`
    this.loginSuccessType = `${this.name}.LOGIN_SUCCESS`
    this.loginFailedType = `${this.name}.LOGIN_FAILURE`
    this.logoutRequestType = `${this.name}.LOGOUT_REQUEST`
    this.logoutSuccessType = `${this.name}.LOGOUT_SUCCESS`
  }

  protected initializeActions() {
    super.initializeActions()

    this.loginRequestAction = createAction(this.loginRequestType)
    this.loginSuccessAction = createAction(this.loginSuccessType)
    this.loginFailedAction = createAction(this.loginFailedType)
    this.logoutRequestAction = createAction(this.logoutRequestType)
    this.logoutSuccessAction = createAction(this.logoutSuccessType)
  }
}

export default new AuthenticatedUserModule()
