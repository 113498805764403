import Users from '../models/Users'
import ModelsModule from './ModelsModule'
import {bind} from '../utils'

const initialState = new Users()

class EventUsersModule extends ModelsModule {

  constructor() {
    super('eventUsers', initialState, {path: 'user'})
    bind(this, this.fetchEventUsers)
  }

  fetchEventUsers(criteria, events, reset: boolean = true) {

    const fileds = ['eventId']

    const eventIdCriteria = criteria.get('eventId')

    if (eventIdCriteria.isEmpty()) {

      const queryParams = {eventId: events.list.toJS().map(event => event.id)}

      return this.getModels(queryParams, reset)

    } else {

      const queryParams = criteria.getQueryParams(undefined, fileds)

      return this.getModels(queryParams, reset)
    }
  }

}

export default new EventUsersModule()
