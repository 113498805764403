import BaseModel from './BaseModel'
import PhotoMetadata from './PhotoMetadata'

const constraints = {}

const interestedKeys = [
  'id',
  'creatorId',
  'eventId',
  'mimeType',
  'filename',
  'status',
  'metadata'
]

export enum PhotoStatus {
  ADDED = 'added',
  ACTIVE = 'active',
  BANNED = 'banned',
  DISABLED = 'disabled',
  PROCESSING = 'processing'
}

enum PhotoStatusColor {
  ADDED = '#ffc400',
  ACTIVE = '#76CB3F',
  BANNED = '#563770',
  DISABLED = '#8C8C8C',
  PROCESSING = '#D24D17',
}

const defaultValues = {
  id: undefined,
  creatorId: undefined,
  eventId: undefined,
  mimeType: undefined,
  filename: undefined,
  status: undefined,
  metadata: new PhotoMetadata()
}

export default class EventPhoto extends BaseModel(defaultValues, interestedKeys, constraints)<EventPhoto> {
    id: number
    creatorId: number
    eventId: number
    mimeType: string
    filename: string
    status: PhotoStatus
    metadata: PhotoMetadata

    constructor(js?) {
      super(js)

      if (js && js.metadata) {
        return this.set('metadata', new PhotoMetadata(js.metadata)) as EventPhoto
      }
    }

    fromJS(js): EventPhoto {
        return new EventPhoto(js)
    }

    static getColorByStatus(status: PhotoStatus) {
        switch (status) {
          case PhotoStatus.ACTIVE:
            return PhotoStatusColor.ACTIVE
          case PhotoStatus.ADDED:
            return PhotoStatusColor.ADDED
          case PhotoStatus.BANNED:
            return PhotoStatusColor.BANNED
          case PhotoStatus.DISABLED:
            return PhotoStatusColor.DISABLED
          case PhotoStatus.PROCESSING:
            return PhotoStatusColor.PROCESSING
        }
    }
}
