import {createAction, handleActions} from 'redux-actions'
import {add, get, remove, update} from './ActionsCommon'
import ModelInterface from '../models/ModelInterface'
import * as _ from 'lodash'
import {bind} from '../utils'
import ModuleOptions from './ModuleOptions'

export default class ModelModule<T extends ModelInterface<any>> {

  readonly debouncedSave
  readonly debouncedUpdate
  protected name: string
  protected initialState: T
  protected path: string
  protected resetModelType: string
  protected getModelRequestType: string
  protected getModelSuccessType: string
  protected getModelFailedType: string
  protected createModelRequestType: string
  protected createModelSuccessType: string
  protected createModelFailedType: string
  protected updateModelRequestType: string
  protected updateModelSuccessType: string
  protected updateModelFailedType: string
  protected deleteModelRequestType: string
  protected deleteModelSuccessType: string
  protected deleteModelFailedType: string
  protected resetModelAction: any
  protected getModelRequestAction: any
  protected getModelSuccessAction: any
  protected getModelFailedAction: any
  protected updateModelRequestAction: any
  protected updateModelSuccessAction: any
  protected updateModelFailedAction: any
  protected createModelRequestAction: any
  protected createModelSuccessAction: any
  protected createModelFailedAction: any
  protected deleteModelRequestAction: any
  protected deleteModelSuccessAction: any
  protected deleteModelFailedAction: any

  constructor(name: string, initialState: T, {path}: ModuleOptions = {}) {
    this.name = name
    this.initialState = initialState
    this.path = path || name
    this.initializeTypes()
    this.initializeActions()
    bind(
      this,
      this.createModel,
      this.getModel,
      this.saveModel,
      this.saveModelDebounced,
      this.updateModel,
      this.updateModelDebounced,
      this.deleteModel,
      this.resetModel,
      this.handleError
    )
    this.debouncedSave = _.debounce((dispatch, model, pathComponents?, queryParams?) => {
      return dispatch(this.saveModel(model, pathComponents, queryParams))
    }, 500)
    this.debouncedUpdate = _.debounce((dispatch, model) => dispatch(this.updateModel(model)), 500)
  }

  public createModel(model, pathComponents?, queryParams?) {

    return (dispatch) => {

      if (model.isValid()) {

        dispatch(this.createModelRequestAction(model))

        return dispatch(add(this.path, pathComponents, queryParams, model))
          .then(response => dispatch(this.createModelSuccessAction(response)))
          .then(() => dispatch(this.onCreateSuccess(model)))
          .catch(error => dispatch(this.handleError(this.createModelFailedAction, error, model)))

      } else {

        return dispatch(this.createModelFailedAction({error: model.validate(), model}))
      }
    }
  }

  public getModel(id, queryParams?) {

    return (dispatch) => {

      dispatch(this.getModelRequestAction())

      return dispatch(get(this.path, id, queryParams))
        .then(response => dispatch(this.getModelSuccessAction(response)))
        .catch(error => dispatch(this.handleError(this.getModelFailedAction, error)))
    }
  }

  public saveModel(model, pathComponents?, queryParams?) {

    return (dispatch) => {

      if (model.id) {

        return dispatch(this.updateModel(model))

      } else {

        return dispatch(this.createModel(model, pathComponents, queryParams))
      }
    }
  }

  public saveModelDebounced(model, pathComponents?, queryParams?) {

    return dispatch => this.debouncedSave(dispatch, model, pathComponents, queryParams)
  }

  public updateModel(model) {

    return (dispatch) => {

      if (model.isValid()) {

        dispatch(this.updateModelRequestAction(model))

        return dispatch(update(this.path, model.id, null, model))
          .then(response => dispatch(this.updateModelSuccessAction(response)))
          .then(() => dispatch(this.onUpdateSuccess(model)))
          .catch(error => dispatch(this.handleError(this.updateModelFailedAction, error, model)))

      } else {

        return dispatch(this.updateModelFailedAction(model.validate(), model))
      }
    }
  }

  public updateModelDebounced(model) {

    return dispatch => this.debouncedUpdate(dispatch, model)
  }

  public deleteModel(model) {

    return (dispatch) => {

      dispatch(this.deleteModelRequestAction())

      return dispatch(remove(this.path, model.id))
        .then(() => dispatch(this.deleteModelSuccessAction(model)))
        .then(() => dispatch(this.onDeleteSuccess(model)))
        .catch(error => dispatch(this.handleError(this.updateModelFailedAction, error, model)))

    }
  }

  public resetModel() {

    return dispatch => dispatch(this.resetModelAction())
  }

  public getActionHandlers() {

    const actionHandlers = {
      [this.getModelRequestType]: (state) => state.startOfLoading(),
      [this.getModelSuccessType]: (state, {payload}) => state.fromJS(payload),
      [this.getModelFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.createModelRequestType]: (_state, {payload}) => payload.startOfSaving(),
      [this.createModelFailedType]: (_state, {payload: {error, model}}) => model.endOfSaving(error),
      [this.createModelSuccessType]: (state, {payload}) => state.fromJS(payload),
      [this.updateModelRequestType]: (_state, {payload}) => payload.startOfSaving(),
      [this.updateModelSuccessType]: (state, {payload}) => state.fromJS(payload),
      [this.updateModelFailedType]: (_state, {payload: {error, model}}) => model.endOfSaving(error),
      [this.deleteModelRequestType]: (state) => state.startOfLoading(),
      [this.deleteModelSuccessType]: () => this.initialState,
      [this.deleteModelFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.resetModelType]: () => this.initialState
    }

    return _.merge(actionHandlers, this.getAdditionalActionHandlers())
  }

  public getReducer() {

    return handleActions(this.getActionHandlers(), this.initialState)
  }

  public getCreateModelRequestType() {

    return this.createModelRequestType
  }

  public getCreateModelSuccessType() {

    return this.createModelSuccessType
  }

  public getCreateModelFailedType() {

    return this.createModelFailedType
  }

  public getUpdateModelRequestType() {

    return this.updateModelRequestType
  }

  public getUpdateModelSuccessType() {

    return this.updateModelSuccessType
  }

  public getUpdateModelFailedType() {

    return this.updateModelFailedType
  }

  public getDeleteModelSuccessType() {

    return this.deleteModelSuccessType
  }

  protected getAdditionalActionHandlers() {

    return null
  }

  protected saveSuccessMessageProps(_model) {

    return undefined
  }

  protected onCreateSuccess(_model) {

    return _dispatch => null // _dispatch => infoKey(`${this.name}.saved`, this.saveSuccessMessageProps(model));
  }

  protected onUpdateSuccess(_model) {

    return _dispatch => null // _dispatch => infoKey(`${this.name}.saved`, this.saveSuccessMessageProps(model));
  }

  protected deleteSuccessMessageProps(_model) {

    return undefined
  }

  protected onDeleteSuccess(model) {

    return _dispatch => this.deleteSuccessMessageProps(model)
  }

  protected initializeTypes() {

    this.resetModelType = `${this.name}.RESET_MODEL`

    this.getModelRequestType = `${this.name}.GET_MODEL_REQUEST`
    this.getModelSuccessType = `${this.name}.GET_MODEL_SUCCESS`
    this.getModelFailedType = `${this.name}.GET_MODEL_FAILED`

    this.createModelRequestType = `${this.name}.CREATE_MODEL_REQUEST`
    this.createModelSuccessType = `${this.name}.CREATE_MODEL_SUCCESS`
    this.createModelFailedType = `${this.name}.CREATE_MODEL_FAILED`

    this.updateModelRequestType = `${this.name}.UPDATE_MODEL_REQUEST`
    this.updateModelSuccessType = `${this.name}.UPDATE_MODEL_SUCCESS`
    this.updateModelFailedType = `${this.name}.UPDATE_MODEL_FAILED`

    this.deleteModelRequestType = `${this.name}.DELETE_MODEL_REQUEST`
    this.deleteModelSuccessType = `${this.name}.DELETE_MODEL_SUCCESS`
    this.deleteModelFailedType = `${this.name}.DELETE_MODEL_FAILED`
  }

  protected initializeActions() {

    this.resetModelAction = createAction(this.resetModelType)

    this.createModelRequestAction = createAction(this.createModelRequestType)
    this.createModelSuccessAction = createAction(this.createModelSuccessType)
    this.createModelFailedAction = createAction(this.createModelFailedType)

    this.getModelRequestAction = createAction(this.getModelRequestType)
    this.getModelSuccessAction = createAction(this.getModelSuccessType)
    this.getModelFailedAction = createAction(this.getModelFailedType)

    this.updateModelRequestAction = createAction(this.updateModelRequestType)
    this.updateModelSuccessAction = createAction(this.updateModelSuccessType)
    this.updateModelFailedAction = createAction(this.updateModelFailedType)

    this.deleteModelRequestAction = createAction(this.deleteModelRequestType)
    this.deleteModelSuccessAction = createAction(this.deleteModelSuccessType)
    this.deleteModelFailedAction = createAction(this.deleteModelFailedType)
  }

  protected handleError(action, error, model?) {

    return (dispatch) => {

      return dispatch(action({error: error.data ? error.data : error, model}))
    }
  }
}
