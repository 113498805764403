import * as React from 'react'
import './Image.less'
import FacePredictionBox from '../FacePredictionBox'
import PhotoMetadata from '../../models/PhotoMetadata'

interface Props {
  id?: number
  photoUrl: string
  alt: string
  photoMetadata?: PhotoMetadata
  getPhotoMetadata?
}

export default class Image extends React.PureComponent<Props, any> {
  renderFacePredictionsBoxes = (photoMetadata?: PhotoMetadata) => {
    if (photoMetadata && photoMetadata.originalSize && photoMetadata.facePredictions) {
      const {originalSize, facePredictions} = photoMetadata
      return facePredictions.filter(facePrediction => facePrediction.faceId !== '-1').map(facePrediction => (
        <FacePredictionBox
          originalSize={originalSize}
          facePrediction={facePrediction}/>
      ))
    }
  }

  componentDidUpdate(prevProps) {
    const {id, photoMetadata, getPhotoMetadata} = this.props
    const facePredictions = photoMetadata ? photoMetadata.facePredictions : undefined
    if (id && prevProps.id !== id) { // Image has changed
      if (!photoMetadata || !facePredictions) { // No photo metadata or face predictions are not available
        getPhotoMetadata(id)
      }
    }
  }

  render() {
    const {photoUrl, alt, photoMetadata} = this.props

    return (
      <div className="image">
        <img className="image__background" src={photoUrl} alt={alt}/>
        <div className="image__content__container">
          <div className="image__faces__container">
            {this.renderFacePredictionsBoxes(photoMetadata)}
            <img className="image__content" src={photoUrl} alt={alt}/>
          </div>
        </div>
      </div>
    )
  }
}
