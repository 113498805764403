/**
 * Created by Mauritz Untamala on 01/10/15.
 */
import * as _ from 'lodash'
import API from '../services/api'
import {stringify} from 'qs'
import {handleGenericError} from './App'

export function getPath(objectName, pathComponents?, queryParams?) {

  let path = '/' + objectName

  if (_.isArray(pathComponents)) {

    pathComponents = pathComponents.join('/')
  }

  if (pathComponents) {

    path = path + '/' + pathComponents
  }

  if (queryParams) {

    path = path + '?' + stringify(queryParams)
  }

  return path
}

export function get(objectName, pathComponents?, queryParams?) {

  return (dispatch, getState) => {

    const token = getState().authenticatedUser.accessToken

    return API.get(getPath(objectName, pathComponents, queryParams), token)
      .catch(error => dispatch(handleGenericError(error)))
  }
}

export function add(objectName, pathComponents, queryParams, object) {

  return (dispatch, getState) => {

    const token = getState().authenticatedUser.accessToken

    return API.post(
      getPath(objectName, pathComponents, queryParams),
      object,
      token)
      .catch(error => dispatch(handleGenericError(error)))
  }
}

export function update(objectName, pathComponents, queryParams?, object?) {

  return (dispatch, getState) => {

    const token = getState().authenticatedUser.accessToken

    return API.put(
      getPath(objectName, pathComponents, queryParams),
      object,
      token)
      .catch(error => dispatch(handleGenericError(error)))
  }
}

export function remove(objectName, pathComponents, queryParams?) {

  return (dispatch, getState) => {

    const token = getState().authenticatedUser.accessToken

    return API.delete(getPath(objectName, pathComponents, queryParams), token)
      .catch(error => dispatch(handleGenericError(error)))
  }
}
