import * as React from 'react'
import * as _ from 'lodash'
import './PhotoGrid.less'
import Image from '../Image/Image'
import Spinner from '../Spinner/Spinner'
import ErrorElement from '../ErrorElement'

interface PhotoData {
  key: string
  src: string
  alt: string
}

interface Props {
  photoData: [PhotoData]
  style?: any
  loading: boolean
  scroll?
  hasMore?: boolean
  loadMore?()
  error?: any
  t: any
  filterComponent?
}

export default class PhotoGrid extends React.Component<Props, any> {

  scroll

  componentDidMount() {
    this.setupInfiniteScroll()
  }

  componentDidUpdate() {
    this.setupInfiniteScroll()
  }

  componentWillUnmount() {
    this.detachScrollListener()
  }

  isInfiniteScroll = () => !!this.props.loadMore

  setupInfiniteScroll() {

    if (!this.isInfiniteScroll()) {

      return
    }

    this.scroll = document.querySelector(`.photo-grid`)
    this.attachScrollListener()
  }

  detachScrollListener = () => {

    if (!this.isInfiniteScroll()) {
      return
    }

    if (this.scroll) {
      this.scroll.removeEventListener('scroll', this.scrollListener)
      this.scroll.removeEventListener('resize', this.scrollListener)
    }
  }

  attachScrollListener = () => {

    if (!this.props.hasMore || !this.isInfiniteScroll()) {

      return
    }

    if (this.scroll) {
      this.scroll.addEventListener('scroll', this.scrollListener)
      this.scroll.addEventListener('resize', this.scrollListener)
      this.scrollListener()
    }
  }

  scrollToTop = () => {
    const element = this.scroll
    if (element) {
      element.scrollTop = 0
    }
  }

  scrollListener = () => {
    const element = this.scroll
    const scrollTop = (element && element.scrollTop) || document.body.scrollTop
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight
    const scrolledToBottom = (scrollTop + element.clientHeight) >= scrollHeight - 20
    const {hasMore, loadMore} = this.props

    if (this.hasScrollBar() && scrolledToBottom && hasMore && loadMore && !this.props.loading) {
      this.detachScrollListener()
      loadMore()
    }
  }

  hasScrollBar = () => {
    const element = this.scroll
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight
    const clientHeight = (element && element.clientHeight) || document.body.clientHeight

    return scrollHeight > clientHeight
  }

  renderPhoto = () => {

    const {error, photoData} = this.props

    if (error) {
      return <ErrorElement error={error.errorCode}/>
    }

    if (_.isEmpty(photoData)) {
      return <ErrorElement error="noPhoto"/>
    }

    return photoData.map(photo => (
      <div className="photo-grid__photo"  key={photo.key}>
        <Image photoUrl={photo.src} alt={photo.alt}/>
      </div>
    ))
  }

  renderSpinner() {

    const {loading} = this.props

    const className = ['photo-grid__spinner']

    if (loading) {
      className.push('photo-grid__spinner--visible')
    }

    return (
      <div className={className.join(' ')}>
        <Spinner size="big"/>
      </div>
    )
  }

  render() {

    return (
      <div style={this.props.style} className="photo-grid">
        <div className="photo-grid__filter">
          {this.props.filterComponent}
        </div>
        <div className="photo-grid__content">
          {this.renderPhoto()}
        </div>
          {this.renderSpinner()}
      </div>
    )
  }
}
