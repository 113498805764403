import * as React from 'react'
import {connect} from 'react-redux'
import AuthenticatedUserModule from '../../../modules/AuthenticatedUser'
import {withRouter} from 'react-router'

const mapActionCreators = {
  logout: AuthenticatedUserModule.logout
}

const mapStateToProps = state => ({
  user: state.user
})

interface Props {
  user: any
  logout
  history
}

class LogoutView extends React.Component<Props, any> {
  componentDidMount() {
    this.props.logout()
    this.props.history.replace('/')
  }

  render() {
    return <div/>
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapActionCreators
  )(LogoutView) as any
)
