import {createAction, handleActions} from 'redux-actions'
import {get} from './ActionsCommon'
import * as _ from 'lodash'
import {PaginationContext} from '../models/Pagination'
import {resetPagination, setPagination} from './Pagination'
import {bind} from '../utils'
import ModuleOptions from './ModuleOptions'
import {updateCriteria} from './Location'

export default class ModelsModule {
  readonly debouncedGetModels
  readonly debouncedUpdateCriteria
  protected name: string
  protected initialState: any
  protected path: string
  protected paginationContext: PaginationContext
  protected pageSize: number
  protected resetModelsType: string
  protected getModelSuccessType: string
  protected getModelsRequestType: string
  protected getModelsSuccessType: string
  protected getModelsFailedType: string
  protected resetModelsAction: any
  protected getModelsRequestAction: any
  protected getModelSuccessAction: any
  protected getModelsSuccessAction: any
  protected getModelsFailedAction: any

  constructor(name, initialState, {path = null, paginationContext = null, pageSize = 10}: ModuleOptions = {}) {
    this.name = name
    this.initialState = initialState
    this.path = path || name
    this.paginationContext = paginationContext
    this.pageSize = pageSize
    this.initializeTypes()
    this.initializeActions()
    bind(
      this,
      this.getModel,
      this.getModels,
      this.resetModels,
      this.updateCriteria,
      this.getModelsByCriteria,
      this.getModelsDebounce,
      this.updateCriteriaDebounce
    )
    this.debouncedGetModels = _.debounce(
      (dispatch, queryParams?, reset = false, modelPath = path, pathComponents = null) =>
        dispatch(this.getModels(queryParams, reset, modelPath, pathComponents)), 300)
    this.debouncedUpdateCriteria = _.debounce(
      (dispatch, location, criteria) =>
        dispatch(updateCriteria(location, criteria)), 300)
  }

  public updateCriteria(location, criteria, updateLocation: boolean = true, query?, fields?, fieldsList?) {

    return dispatch => {

      if (updateLocation) {
        dispatch(this.updateCriteriaDebounce(location, criteria))
      }

      const queryParams = criteria.getQueryParams(fields, fieldsList)

      return dispatch(this.getModelsDebounce(_.merge(queryParams, query), true, undefined, undefined))
    }
  }

  public getModelsDebounce(queryParams?, reset = false, path = this.path, pathComponents = null, size?) {

    return dispatch => this.debouncedGetModels(dispatch, queryParams, reset, path, pathComponents, size)
  }

  public getModelsByCriteria(criteria, query?, fields?) {

    return dispatch => {

      const queryParams = criteria.getQueryParams(fields)
      return dispatch(this.getModels(_.merge(queryParams, query), false, undefined, undefined))
    }
  }

  public updateCriteriaDebounce(location, criteria) {

    return dispatch => this.debouncedUpdateCriteria(dispatch, location, criteria)
  }

  public getModel(id, queryParams?) {

    return (dispatch) => {

      dispatch(this.getModelsRequestAction())

      return dispatch(get(this.path, id, queryParams))
        .then(response => dispatch(this.getModelSuccessAction(response)))
        .catch(err => dispatch(this.getModelsFailedAction(err)))
    }
  }

  public getModels(queryParams?, reset = false, path = this.path, pathComponents = null) {

    return (dispatch, getState) => {

      dispatch(this.getModelsRequestAction())

      const pagination = this.getPagination(getState())

      if (pagination) {

        if (reset) {
          pagination.offset = 0
        }

        queryParams = _.merge({}, queryParams, pagination)
      }

      return dispatch(get(path, pathComponents, queryParams))
        .then(response => {

          if (pagination) {

            pagination.offset = pagination.offset + response.length

            pagination.hasMore = response.length >= pagination.limit

            dispatch(setPagination(this.paginationContext, pagination))

            return dispatch(this.receiveModels(response, reset, queryParams))

          } else {

            return dispatch(this.receiveModels(response, reset, queryParams))
          }
        })
        .catch(err => dispatch(this.getModelsFailedAction(err)))
    }
  }

  public receiveModels(results, reset = false, queryParams?) {

    return dispatch => dispatch(this.getModelsSuccessAction({results, reset, queryParams}))
  }

  public resetModels() {

    return dispatch => {

      if (this.paginationContext) {

        dispatch(resetPagination(this.paginationContext))
      }

      dispatch(this.resetModelsAction())
    }
  }

  public getActionHandlers() {

    const actionHandlers = {
      [this.getModelsRequestType]: (state) => state.startOfLoading(),
      [this.getModelSuccessType]: (state, {payload}) => state.addModelFromJS(payload).endOfLoading(),
      [this.getModelsSuccessType]: (state, {payload: {results = [], reset = false}}) => {
        return (reset ? state.setModelsFromJS(results) : state.addModelsFromJS(results)).endOfLoading()
      },
      [this.getModelsFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.resetModelsType]: () => this.initialState
    }

    return _.merge(actionHandlers, this.getAdditionalActionHandlers())
  }

  public getReducer() {

    return handleActions(this.getActionHandlers(), this.initialState)
  }

  protected getAdditionalActionHandlers() {

    return null
  }

  protected initializeTypes() {

    this.resetModelsType = `${this.name}.RESET_MODELS`

    this.getModelSuccessType = `${this.name}.GET_MODEL_SUCCESS`

    this.getModelsRequestType = `${this.name}.GET_MODELS_REQUEST`
    this.getModelsSuccessType = `${this.name}.GET_MODELS_SUCCESS`
    this.getModelsFailedType = `${this.name}.GET_MODELS_FAILED`
  }

  protected initializeActions() {

    this.resetModelsAction = createAction(this.resetModelsType)

    this.getModelSuccessAction = createAction(this.getModelSuccessType)

    this.getModelsRequestAction = createAction(this.getModelsRequestType)
    this.getModelsSuccessAction = createAction(this.getModelsSuccessType)
    this.getModelsFailedAction = createAction(this.getModelsFailedType)
  }

  protected handleError(action, error) {

    return (dispatch) => {

      return dispatch(action(error.data ? error.data : error))

    }
  }

  private getPagination(state) {

    if (!this.paginationContext) {
      return null
    }

    const {offset} = state.pagination.get(this.paginationContext)

    return {
      offset: offset || 0,
      limit: this.pageSize
    } as any
  }
}
