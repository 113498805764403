/**
 * Created by Mauritz Untamala on 16/02/2017.
 */
import BaseModel from './BaseModel'
import Profile from './Profile'

const interestedKeys = ['email', 'role', 'profile']

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
  ANONYMOUS = 'anonymous'
}

const defaultValues = {
  accessToken: undefined,
  profile: undefined,
  email: undefined,
  role: UserRole.USER,
  error: undefined,
  isLoading: false,
  isAuthenticated: false
}

export default class User extends BaseModel(defaultValues, interestedKeys)<User> {

  role: string
  profile: Profile
  email: string

  constructor(js?) {
    super(js)

    if (js && js.profile) {
      return this.set('profile', new Profile(js.profile)) as User
    }
  }

  fromJS(js): User {

    return new User(js)
  }

  setAuthenticated(isAuthenticated) {

    return this.set('isAuthenticated', isAuthenticated)
  }

  isAdmin() {
    return this.role === UserRole.ADMIN
  }

  isUser() {
    return this.role === UserRole.USER
  }

  isAnonymous() {
    return this.role === UserRole.ANONYMOUS
  }
}
