import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'

const locationHelper = locationHelperBuilder({})

export const UserIsAuthenticated = connectedRouterRedirect({
  authenticatedSelector: state => !!state.authenticatedUser && state.authenticatedUser.isAuthenticated,
  authenticatingSelector: state => state.authenticatedUser.isLoading,
  redirectPath: '/login',
  wrapperDisplayName: 'UserIsAuthenticated'
})

export const UserIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (_, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: state => !state.authenticatedUser
    || (!!state.authenticatedUser && !state.authenticatedUser.isAuthenticated),
  wrapperDisplayName: 'UserIsNotAuthenticated'
})
