import * as React from 'react'
import './AuthLayout.less'
import {Grid} from 'react-bootstrap'

interface Props {
  children: any
}

export const AuthLayout: React.SFC<Props> = ({children}) => (
  <div className="auth-layout">
    <Grid>
      {children}
    </Grid>
  </div>
)

export default AuthLayout
