import * as React from 'react'
import {MenuItem, Nav, Navbar, NavDropdown, NavItem} from 'react-bootstrap'
import {withNamespaces} from 'react-i18next'
import {LinkContainer} from 'react-router-bootstrap'
import {NavLink} from 'react-router-dom'
import User from '../../models/User'
import './Header.less'

const LogoImg = require('../../assets/images/generic-logo.svg')

const ADMIN_LINKS = [
  {to: '/admin/partners', title: 'partners'}
]

interface Props {
  authenticatedUser: User
  t
  tReady
  i18n
}

export class Header extends React.Component<Props, any> {

  createLinkContainer = ({to, title}, i) => {

    return <LinkContainer key={i} to={to}><NavItem>{this.props.t(title)}</NavItem></LinkContainer>
  }

  getAdminLinks() {

    return (
      <div id="admin-links">
        <Nav>{ADMIN_LINKS.map(this.createLinkContainer)}</Nav>
      </div>
    )
  }

  getNavigationForRole() {

    if (this.props.authenticatedUser && this.props.authenticatedUser.isAdmin()) {
      return this.getAdminLinks()
    }

    return null
  }

  render() {

    const {authenticatedUser: {profile}, t} = this.props
    const userDisplayName = (profile && profile.nickname) ? profile.nickname : t('account')

    return (
      <Navbar inverse={true} staticTop={true} id="main-navigation">
        <Navbar.Header>
          <Navbar.Brand>
            <NavLink to="/" title="Index"><img src={LogoImg} height={30} /></NavLink>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          {this.getNavigationForRole()}
          <Nav pullRight={true}>
            <NavDropdown
              title={userDisplayName}
              id="user-nav-dropdown">
              <LinkContainer to="/logout"><MenuItem>{t('signOut')}</MenuItem></LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default withNamespaces('nav', {wait: true})(Header)
