import * as React from 'react'
import {Route} from 'react-router'
import CoreLayout from '../layouts/CoreLayout'
import Admin from './Admin'
import Authentication from './Authentication'
import Home from './Home'
import PresentEventPhotos from './PresentEventPhotos'

const RouteWithSubRoutes = (config) => {
  const renderChildComponent = () => {
    if (!config.childRoutes) {
      return undefined
    }

    return config.childRoutes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)
  }

  const renderComponent = props => {

    return config.component ?
      <config.component {...props}>{renderChildComponent()}</config.component>
      : null
  }

  return (
    <Route
      path={config.path}
      exact={config.exact}
      render={renderComponent}
    />
  )
}

export default (
    <CoreLayout>
      <RouteWithSubRoutes key="admin-route" {...Admin} />
      <RouteWithSubRoutes key="auth-route" {...Authentication} />
      <RouteWithSubRoutes key="home-route" {...Home} />
      <RouteWithSubRoutes key="presentation-route" {...PresentEventPhotos} />
    </CoreLayout>
)
