import i18n from 'i18next'
import * as moment from 'moment'

const XHR = require('i18next-xhr-backend')
const LanguageDetector = require('i18next-browser-languagedetector')
// tslint:disable-next-line:no-implicit-dependencies
const resources = require('i18next-resource-store-loader!../locales/index')

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
      fallbackLng: 'en',

      // have a common namespace used around the full app
      ns: ['common'],
      defaultNS: 'common',

      debug: true,

      resources,

      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
        format: (value, format) => {

          if (format === 'uppercase') {
            return value.toUpperCase()
          }

          return value
        }
      }
    },
    () => {
      moment.locale(i18n.language)
    }
  )

export default i18n
