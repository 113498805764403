import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {createBrowserHistory} from 'history'
import createStore from './store/createStore'
import AppContainer from './containers/AppContainer'
import State from './models/State'
import {I18nextProvider} from 'react-i18next'
import i18n from './components/I18n'

// const appConfig: any = require('./config');

// ========================================================
// Store Instantiation
// ========================================================
const initialState = State()

const history = createBrowserHistory()

const store = createStore(initialState, history)

const MOUNT_NODE: any = document.getElementById('root')

const render = () => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <AppContainer store={store} history={history}/>
    </I18nextProvider>,
    MOUNT_NODE
  )
}

// ========================================================
// Developer Tools Setup
// ========================================================
/*if (appConfig.env === 'development') {
  // Development render functions
  const renderApp = render;
  const renderError = (error: any) => {
    const RedBox = require('redbox-react').default;

    ReactDOM.render(<RedBox error={error}/>, MOUNT_NODE);
  };

  // Wrap render in try/catch
  render = () => {
    try {
      renderApp();
    } catch (error) {
      renderError(error);
    }
  };
}*/

render()
